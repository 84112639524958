import React from 'react';
import { NextUIProvider } from '@nextui-org/react';
// import NavBar from './components/navbar';
import Links from './components/links';

function App() {
  return (
    <NextUIProvider>
      <Links />
    </NextUIProvider>
  );
}

export default App;
