import React from 'react';
import { Card, CardBody, Button } from '@nextui-org/react';
import { FaYoutube, FaTiktok } from 'react-icons/fa';

const Hero = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gradient-to-r from-blue-500 to-pink-500">
      {/* Blurred Card with Title */}
      <div className="bg-black bg-opacity-50 p-10 rounded-lg text-center backdrop-blur-lg">
        <Card>
          <CardBody>
            <h1 className="text-5xl font-bold mb-4 text-white">
              Welcome to Pixel Sized Tech
            </h1>
          </CardBody>
        </Card>
      </div>
      
      {/* Social Links in Grid */}
      <div className="grid grid-cols-2 gap-4 mt-8">
        {/* YouTube */}
        <a href="https://www.youtube.com/@PixelSizedTech" target="_blank" rel="noopener noreferrer">
          <Card isBlurred shadow="ld" className="border-none dark:bg-black/30 rounded-3xl transform transition duration-500 ease-in-out hover:scale-105 w-72 h-auto mt-4 text-white">
            <CardBody className="p-5 flex items-center justify-between">
              <div className="flex items-center">
                <FaYoutube size={24} className="mr-2" />
                <h3 className="font-bold text-lg">YouTube</h3>
              </div>
              <p className="text-sm text-gray-400">Where to get my long tutorials about Salad, tech, and other apps I think are nice</p>
              <Button flat auto rounded>
              </Button>
            </CardBody>
          </Card>
        </a>
        
        {/* TikTok */}
        <a href="https://www.tiktok.com/@pixelsizedtech" target="_blank" rel="noopener noreferrer">
          <Card isBlurred shadow="ld" className="border-none dark:bg-black/30 rounded-3xl transform transition duration-500 ease-in-out hover:scale-105 w-72 h-auto mt-4 text-white">
            <CardBody className="p-5 flex items-center justify-between">
              <div className="flex items-center">
                <FaTiktok size={24} className="mr-2" />
                <h3 className="font-bold text-lg">TikTok</h3>
              </div>
              <p className="text-sm text-gray-400">Where to get my short tutorials about Salad, tech, and other apps I think are nice</p>
              <Button flat auto rounded>
              </Button>
            </CardBody>
          </Card>
        </a>
        
        {/* Salad Download */}
        <a href="https://www.salad.com/download" target="_blank" rel="noopener noreferrer">
          <Card isBlurred shadow="ld" className="border-none dark:bg-black/30 rounded-3xl transform transition duration-500 ease-in-out hover:scale-105 w-72 h-auto mt-4 text-white">
            <CardBody className="p-5 flex items-center justify-between">
              <div className="flex items-center">
                <img src="/salad.png" alt="Salad" className="w-8 h-8 mr-2" />
                <h3 className="font-bold text-lg">Download Salad</h3>
              </div>
              <p className="text-sm text-gray-400">Use code "Pixel" when signing up for a 2x bonus for your first 4 dollars</p>
              <Button flat auto rounded>
              </Button>
            </CardBody>
          </Card>
        </a>
      </div>
    </div>
  );
};

export default Hero;
